<template>
    <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        User Profile
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
        <li class="breadcrumb-item"><a href="#">Examples</a></li>
        <li class="breadcrumb-item active">Your profile</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">

      <div class="row">
        <div class="col-lg-4">
          <!-- Profile Image -->
          <div class="box">
            <div class="box-body box-profile">
              <img class="profile-user-img rounded-circle img-fluid mx-auto d-block" src="@/assets/images/account2.png" alt="User profile picture">
              <h3 class="profile-username text-center">{{ firstName }} {{ lastName }}</h3>
              <p class="text-center">Admin</p>				
              <div class="row social-states">
				  <!-- <div class="col-6 text-right"><a href="#" class="link"><i class="ion ion-ios-people-outline"></i> 254</a></div> -->
				  <!-- <div class="col-6 text-left"><a href="#" class="link"><i class="ion ion-images"></i> 54</a></div> -->
			  </div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->
        </div>
        <div class="col-lg-4">
          <!-- Profile Image -->
          <div class="box">
            <div class="box-body box-profile">
				<div class="profile-user-info">
					<p>Email address </p>
					<h4 class="mb-20">{{ email }}</h4>
					<p>Phone</p>
					<h4 class="mb-20">{{ phone }}</h4> 
				</div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->
        </div>
		  
        <div class="col-12">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              
              <li><a class="active" href="#settings" data-toggle="tab">Settings</a></li>
              <!-- <li><a href="#change-password" data-toggle="tab">Change password</a></li> -->
              <!-- <li><a class="active" href="#timeline" data-toggle="tab">Timeline</a></li>
              <li><a href="#activity" data-toggle="tab">Activity</a></li> -->
            </ul>
                        
            <div class="tab-content">
              
                <div class="tab-pane active" id="settings">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 col-12">
                        <div class="row">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input v-model="firstName" type="text" class="form-control" placeholder="First Name">
                                    <p class="error" v-if="firstNameError">{{firstNameErrorMessage}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input v-model="lastName" type="text" class="form-control" placeholder="Last Name">
                                    <p class="error" v-if="lastNameError">{{lastNameErrorMessage}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input v-model="email" type="text" class="form-control" placeholder="Email">
                                    <p class="error" v-if="emailError">{{emailErrorMessage}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input v-model="phone" type="text" class="form-control" placeholder="Phone">
                                    <p class="error" v-if="phoneError">{{phoneErrorMessage}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <button class="btn btn-lg btn-primary" @click="updateAdminData">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
          <!-- /.nav-tabs-custom -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->

    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import { mapGetters } from 'vuex';
import { repository } from '../../presenter';
export default {
  name: 'Profile',
  computed: {
      ...mapGetters('user',{
          profile: 'retrieveAdminProfile',
      })
  },
  watch: {
      profile: function(profile){
        if (!profile) return;
        this.firstName = profile.first_name;
        this.lastName = profile.last_name;
        this.email = profile.email;
        this.phone = profile.phone;
      },
  },
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)
    this.loadAdminProfile();
    if (!this.profile || this.profile==null){
        console.log("loading admin profile in profile")
        
    }
  },
  data() {
      return {
        publicPath: process.env.BASE_URL,

        firstName: this.profile ? this.profile.first_name : '',
        firstNameError: false,
        firstNameErrorMessage: '',
        lastName: this.profile ? this.profile.last_name : '',
        lastNameError: false,
        lastNameErrorMessage: '',
        email:  this.profile ? this.profile.email : '',
        emailError: false,
        emailErrorMessage: '',
        phone:  this.profile ? this.profile.phone : '',
        phoneError: false,
        phoneErrorMessage: '',

        loading: false,
        error: false,
        errorMessage:'',
      }
  },
  methods:{
        loadAdminProfile(){
            repository.user.loadAdminProfile();
        },

        async updateAdminData () {
            if (this.loading) return
            this.resetData()
            const adminDataValidation = this.validateAdminData()
            if (!adminDataValidation.success) return
            this.loading = true
            const data = {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
            }
            console.log("update profile data is ", data)
            const response = await repository.user.updateProfile(data)
            this.loading = false
            if (response.success) {
                this.showSuccessMessage('Profile Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

        validateAdminData(){
            const returnValue = new Object()
            if (!this.firstName){
                this.firstNameError=true;
                this.firstNameErrorMessage='Enter first name';
                returnValue.success = false
                return returnValue
            }
            if (!this.lastName){
                this.lastNameError=true;
                this.lastNameErrorMessage='Enter last name';
                returnValue.success = false
                return returnValue
            }

            if (!this.email) {
                this.emailError=true;
                this.emailErrorMessage='Enter valid email';
                returnValue.success = false
                return returnValue
            }

            if (!this.phone) {
                this.phoneError=true;
                this.phoneErrorMessage='Enter valid phone number';
                returnValue.success = false
                return returnValue
            }

            
            returnValue.success = true
            return returnValue
        },

        showSuccessMessage (message) {
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },

        resetData() {
            this.error = false
            this.errorMessage = ''
            this.firstNameError = false
            this.lastNameError = false
            this.emailError = false
            this.phoneError = false
        },


    }
}
</script>


<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>
